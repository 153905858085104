export default {
    lang: 'zh-cn',
    'zh-cn': {
        title: '取色板',
        ok: '确定',
        cancel: '取消'
    },
    'en-us': {
        title: 'Color Palette',
        ok: 'OK',
        cancel: 'Cancel',
    },
    'zh-tw': {
        title: '取色板',
        ok: '確定',
        cancel: '取消'
    },
    'vi-vn': {
        title: 'bảng màu',
        ok: 'Chắc chắn',
        cancel: 'Hủy bỏ'
    }
};
