export default {
    lang: 'en_US',
    Popconfirm: {
        'ok': 'OK',
        'cancel': 'Cancel'
    },
    Table: {
        'resetSettings': 'Reset',
        'include': 'Include',
        'exclusive': 'Not include',
        'equal': 'Equal to',
        'unequal': 'Not equal to',
        'begin': 'Begin with',
        'end': 'End with',
        'greaterThan': 'Greater than',
        'greatThanEqualTo': 'Greater than or equal to',
        'lessThan': 'Less than',
        'lessThanEqualTo': 'Less than or equal to',
        'beEqualTo': 'Equal to',
        'notEqualTo': 'Not equal to',
        "noData": 'No data',
    },
    Clipboard: {
        'copy': 'Copy',
        'cut': 'Cut',
        'copyReady': 'Copied',
        'cutReady': 'Cut',
        'copyToClipboard': 'Copy to Clipboard',
        'close': 'Close',
    },
    Pagination: {
        'total': 'Total',
        'items': 'items',
        'show': 'Show',
        'goto': 'Goto',
        'page': '',
        'ok': 'OK'
    },
    Grid: {
        'fixTitle': 'Lock',
        'noFixTitle': 'Unlock',
        'hideTitle': 'Hide',
        'rowFilter': 'Row Filter',
        'openRowFilter': 'Enable Row Filter',
        'closeRowFilter': 'Disable Row Filter',
    },
    SearchPanel: {
        'title': 'Default Filter',
        'resetName': 'Clear',
        'searchName': 'Query',
        'down': 'Expand',
        'up': 'Collapse',
    },
    InputNumber: {
        'msgMax': 'Cannot be greater than the Max value',
        'msgMin': 'Cannot be less than the Min value'
    },
    Modal: {
        'ok': 'OK',
        'gotit': 'OK',
        'cancel': 'Cancel',
    },
    Loading: {
        'loading': 'Loading...',
    },
    Upload: {
        'remove': 'delete',
        'down': 'download',
        'fileLoading': 'The file on the cross'
    }
}
