import enUS from 'rc-calendar/lib/locale/en_US';
import viVN from 'rc-calendar/lib/locale/vi_VN';
import zhCN from 'rc-calendar/lib/locale/zh_CN';
import zhTW from 'rc-calendar/lib/locale/zh_TW';
import idID from 'rc-calendar/lib/locale/id_ID';
import dateLocale from '../../../wui-locale/src/dateLocale';

const i18n = {
    'lang': 'zh-cn',
    'zh-cn': {
        ...zhCN,
        clickSelectColomn: '点击此处选择纵列',
        clickSelect: '点击此处选中',
        date: '日',
        toLastDay: '上一天',
        toNextDay: '下一天',
        toLastMonth: '上个月',
        toNextMonth: '下个月',
        allDay: '全天',
        janFirst: '1月1日',
        febFirst: '2月1日',
        marFirst: '3月1日',
        aprFirst: '4月1日',
        mayFirst: '5月1日',
        junFirst: '6月1日',
        julFirst: '7月1日',
        augFirst: '8月1日',
        sepFirst: '9月1日',
        octFirst: '10月1日',
        novFirst: '11月1日',
        decFirst: '12月1日',
        ok: '确认',
        cancel: '取消',
        modalHeader: '创建任务',
        modalBody: '请自定义内容',
        moreBefore: '还有',
        moreAfter: '项',
        more: '更多',
        week: '周',
        ...dateLocale['zh-cn']
    },
    'en-us': {
        ...enUS,
        clickSelectColomn: 'click here to select the colomn',
        clickSelect: 'click here to select',
        date: 'date',
        toLastDay: 'toLastDay',
        toNextDay: 'toNextDay',
        toLastMonth: 'toLastMonth',
        toNextMonth: 'toNextMonth',
        allDay: 'allDay',
        janFirst: 'Jan 1',
        febFirst: 'Feb 1',
        marFirst: 'Mar 1',
        aprFirst: 'Apr 1',
        mayFirst: 'May 1',
        junFirst: 'Jun 1',
        julFirst: 'Jul 1',
        augFirst: 'Aug 1',
        sepFirst: 'Sep 1',
        octFirst: 'Oct 1',
        novFirst: 'Nov 1',
        decFirst: 'Dec 1',
        ok: 'Ok',
        cancel: 'Cancel',
        modalHeader: 'Create task',
        modalBody: 'Please customize the content',
        moreBefore: 'Remaining',
        moreAfter: 'items',
        more: 'More',
        week: 'weeks',
        ...dateLocale['en-us']
    },
    'zh-tw': {
        ...zhTW,
        clickSelectColomn: '點擊此處選擇縱列',
        clickSelect: '點擊此處選中',
        date: '日',
        toLastDay: '上一天',
        toNextDay: '下一天',
        toLastMonth: '上个月',
        toNextMonth: '下个月',
        allDay: '全天',
        janFirst: '1月1日',
        febFirst: '2月1日',
        marFirst: '3月1日',
        aprFirst: '4月1日',
        mayFirst: '5月1日',
        junFirst: '6月1日',
        julFirst: '7月1日',
        augFirst: '8月1日',
        sepFirst: '9月1日',
        octFirst: '10月1日',
        novFirst: '11月1日',
        decFirst: '12月1日',
        ok: '確認',
        cancel: '取消',
        modalHeader: '創建任務',
        modalBody: '請自定義內容',
        moreBefore: '還有',
        moreAfter: '項',
        more: '更多',
        week: '周',
        ...dateLocale['zh-tw']
    },
    'vi-vn': {
        ...viVN,
        clickSelectColomn: 'Bấm vào đây để chọn một cột',
        clickSelect: 'Bấm vào đây để chọn',
        date: 'ngày',
        toLastDay: 'Ngày cuối',
        toNextDay: 'ngày hôm sau',
        toLastMonth: 'cuối tháng',
        toNextMonth: 'tháng tới',
        allDay: 'cả ngày',
        janFirst: 'Thg 01 1',
        febFirst: 'Thg 02 1',
        marFirst: 'Thg 03 1',
        aprFirst: 'Thg 04 1',
        mayFirst: 'Thg 05 1',
        junFirst: 'Thg 06 1',
        julFirst: 'Thg 07 1',
        augFirst: 'Thg 08 1',
        sepFirst: 'Thg 09 1',
        octFirst: 'Thg 10 1',
        novFirst: 'Thg 11 1',
        decFirst: 'Thg 12 1',
        ok: 'Xác nhận',
        cancel: 'Hủy bỏ',
        modalHeader: 'Để tạo ra một nhiệm vụ',
        modalBody: 'Tùy chỉnh nội dung',
        moreBefore: 'Còn',
        moreAfter: 'mục',
        more: 'Nhiều hơn',
        week: 'tuần',
        ...dateLocale['vi-vn']
    },
    'id-id': { ...idID,
        clickSelectColomn: "Klik untuk memilih kolom",
        clickSelect: "Klik untuk memilih",
        date: 'tanggal',
        toLastDay: "Hari sebelumnya",
        toNextDay: "Hari berikutnya",
        toLastMonth: "Bulan lalu",
        toNextMonth: "Bulan depan",
        allDay: "sepanjang hari",
        janFirst: 'Jan 1',
        febFirst: 'Feb 1',
        marFirst: 'Mar 1',
        aprFirst: 'Apr 1',
        mayFirst: 'Mei 1',
        junFirst: 'Jun 1',
        julFirst: 'Jul 1',
        augFirst: 'Agu 1',
        sepFirst: 'Sep 1',
        octFirst: 'Okt 1',
        novFirst: 'Nov 1',
        decFirst: 'Des 1',
        ok: 'konfirmasi',
        cancel: 'batalkan',
        modalHeader: 'Membuat tugas',
        modalBody: 'Silahkan sesuaikan isinya',
        moreBefore: 'tersisa',
        moreAfter: 'item',
        more: 'Lebih banyak',
        week: 'minggu',
        ...dateLocale['id-id']
    },

}
export default i18n
