

export default {
    locale: 'en-us',
    today: 'Today',
    now: 'Now',
    backToToday: 'Back to today',
    ok: 'Ok',
    clear: 'Clear',
    month: 'Month',
    year: 'Year',
    timeSelect: 'select time',
    dateSelect: 'select date',
    weekSelect: 'Choose a week',
    monthSelect: 'Choose a month',
    yearSelect: 'Choose a year',
    decadeSelect: 'Choose a decade',

    firstHalfYear: 'First Half',
    secondHalfYear: 'Second Half',

    yearFormat: 'YYYY',
    quarterFormat: '[Q]Q',
    quarter: 'Q',
    dateFormat: 'M/D/YYYY',
    dayFormat: 'D',
    dateTimeFormat: 'M/D/YYYY HH:mm:ss',
    monthBeforeYear: true,
    previousMonth: 'Previous month',
    nextMonth: 'Next month',
    previousYear: 'Last year',
    nextYear: 'Next year',
    previousDecade: 'Last decade',
    nextDecade: 'Next decade',
    previousCentury: 'Last century',
    nextCentury: 'Next century'
};
