
export default {
    locale: 'zh-tw',
    today: '今天',
    now: '此刻',
    backToToday: '返回今天',
    ok: '確定',
    timeSelect: '選擇時間',
    dateSelect: '選擇日期',
    clear: '清除',
    month: '月',
    year: '年',
    previousMonth: '上個月',
    nextMonth: '下個月',
    monthSelect: '選擇月份',
    yearSelect: '選擇年份',
    decadeSelect: '選擇年代',

    firstHalfYear: '上半年',
    secondHalfYear: '下半年',

    yearFormat: 'YYYY年',
    quarterFormat: 'Q[季度]',
    quarter: '季度',
    dayFormat: 'D日',
    dateFormat: 'YYYY年M月D日',
    dateTimeFormat: 'YYYY年M月D日 HH時mm分ss秒',
    previousYear: '上一年',
    nextYear: '下一年',
    previousDecade: '上一年代',
    nextDecade: '下一年代',
    previousCentury: '上一世紀',
    nextCentury: '下一世紀'
};
