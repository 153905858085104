module.exports = {
    lang: 'zh-cn',
    ok: '确定',
    cancel: '取消',
    'zh-cn': {
        ok: '确定',
        cancel: '取消'
    },
    'en-us': {
        ok: 'ok',
        cancel: 'cancel'
    },
    'zh-tw': {
        cancel: '取消',
        ok: '確定'
    },
    'vi-vn': {
        ok: 'Chắc chắn',
        cancel: 'Hủy bỏ'
    },
    'id-id': {
        'ok': 'Baik',
        'cancel': 'Batal',
    }
};
