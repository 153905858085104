export default {
    'lang': 'zh-cn',
    'notFoundContent': '暂无数据',
    'zh-cn': {
        'notFoundContent': '暂无数据',
        'source': '可选列表',
        'target': '已选列表',
        'searchPlaceholder': '搜索',
    },
    'en-us': {
        'notFoundContent': 'Not Found',
        'source': 'Source',
        'target': 'Target',
        'searchPlaceholder': 'Search'
    },
    'zh-tw': {
        'notFoundContent': '暫無數據',
        'source': '可選列表',
        'target': '已選列表',
        'searchPlaceholder': '搜索'
    },
    'id-id': {
        'notFoundContent': 'Tidak ditemukan',
        'source': 'Daftar tersedia',
        'target': 'Daftar terpilih',
        'searchPlaceholder': 'Cari',
    }
}
