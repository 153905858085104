import * as React from 'react';
import { RTLContextProps } from './iProvider'


const RTLContext = React.createContext<string | undefined>(undefined);

// eslint-disable-next-line react/prop-types
export const RTLContextProvider: React.FC<RTLContextProps> = ({children, direction}) => (
    <RTLContext.Consumer>
        {originDirection => (
            <RTLContext.Provider value={direction || originDirection}>{children}</RTLContext.Provider>
        )}
    </RTLContext.Consumer>
);

export default RTLContext;
