(function webpackUniversalModuleDefinition(root, factory) {
	if(typeof exports === 'object' && typeof module === 'object')
		module.exports = factory(require("react"), require("react-dom"));
	else if(typeof define === 'function' && define.amd)
		define("TinperNext", ["react", "react-dom"], factory);
	else if(typeof exports === 'object')
		exports["TinperNext"] = factory(require("react"), require("react-dom"));
	else
		root["TinperNext"] = factory(root["React"], root["ReactDOM"]);
})(self, function(__WEBPACK_EXTERNAL_MODULE__42408__, __WEBPACK_EXTERNAL_MODULE__27599__) {
return 