module.exports = {
    'lang': 'zh-cn',
    'total': '共',
    'items': '条',
    'show': '显示',
    'goto': '跳至',
    'page': '页',
    'ok': '确定',
    'prev': '上一页',
    'next': '下一页',
    'first': '返回首页',
    'last': '跳至尾页',
    'loading': '加载中...',
    'zh-cn': {
        'total': '共',
        'items': '条',
        'show': '显示',
        'goto': '跳至',
        'page': '页',
        'ok': '确定',
        'prev': '上一页',
        'next': '下一页',
        'first': '返回首页',
        'last': '跳至尾页',
        'loading': '加载中...',
    },
    'en-us': {
        'total': 'Total',
        'items': 'items',
        'show': 'Show',
        'goto': 'Goto',
        'page': 'Page',
        'ok': 'OK',
        'prev': 'Previous page',
        'next': 'Next page',
        'first': 'Go to first page',
        'last': 'Go to last page',
        'loading': 'loading...',
    },
    'zh-tw': {
        'total': '共',
        'items': '條',
        'show': '顯示',
        'goto': '跳至',
        'page': '頁',
        'ok': '確定',
        'prev': '上一頁',
        'next': '下一頁',
        'first': '返回首頁',
        'last': '跳至尾頁',
        'loading': '加載中...',
    },
    'vi-vn': {
        'total': 'Tổng',
        'items': 'mục',
        'show': 'trình',
        'goto': 'Đến',
        'page': 'Trang',
        'ok': 'xác nhận',
        'prev': 'Trang Trước',
        'next': 'Trang Kế',
        'first': 'trước',
        'last': 'cuối',
        'loading': 'đang tải...',
    },
    'id-id': {
        'total': 'Total',
        'items': 'item',
        'show': 'Tampilkan',
        'goto': 'Pergi ke',
        'page': 'Halaman',
        'ok': 'Baik',
        'prev': 'Halaman sebelumnya',
        'next': 'Halaman selanjutnya',
        'first': 'Kembali ke halaman pertama',
        'last': 'Pergi ke halaman terakhir',
        'loading': 'Memuat...',
    }
}
