export default {
    'zh-cn': async() => {
        return await import('../../wui-locale/src/i18n/zh-cn');
    },
    'en-us': async() => {
        return await import('../../wui-locale/src/i18n/en-us');
    },
    'id-id': async() => {
        return await import('../../wui-locale/src/i18n/id-id');
    },
    'zh-tw': async() => {
        return await import('../../wui-locale/src/i18n/zh-tw');
    },
}