export default {
    locale: 'id-id',
    today: 'Hari ini',
    now: 'Sekarang',
    backToToday: 'Kembali ke hari ini',
    ok: 'Baik',
    timeSelect: 'pilih waktu',
    dateSelect: 'pilih tanggal',
    weekSelect: 'Pilih satu minggu',
    clear: 'Bersih',
    month: 'Bulan',
    year: 'Tahun',
    previousMonth: 'Bulan sebelumnya',
    nextMonth: 'Bulan selanjutnya',
    monthSelect: 'Pilih satu bulan',
    yearSelect: 'Pilih satu tahun',
    decadeSelect: 'Pilih satu dekade',
    firstHalfYear: 'Setengah Tahun Pertama',
    secondHalfYear: 'Setengah Tahun Kedua',
    yearFormat: 'YYYY',
    quarterFormat: 'Q [Kuartal]',
    quarter: 'Kuartal',
    dateFormat: 'D/M/YYYY',
    dayFormat: 'D',
    dateTimeFormat: 'D/M/YYYY HH:mm:ss',
    previousYear: 'Tahun lalu (Control + kiri)',
    nextYear: 'Tahun selanjutnya (Kontrol + kanan)',
    previousDecade: 'Dekade terakhir',
    nextDecade: 'Dekade berikutnya',
    previousCentury: 'Abad terakhir',
    nextCentury: 'Abad berikutnya',
    monthBeforeYear: true,
};

