module.exports = {
    'lang': 'zh-cn',
    'fold': '收起',
    'unfold': '展开',
    'zh-cn': {
        'fold': '收起',
        'unfold': '展开',
    },
    'en-us': {
        'fold': 'Fold',
        'unfold': 'unFold',
    },
    'zh-tw': {
        'fold': '收起',
        'unfold': '展開',
    },
    'vi-vn': {
        'fold': 'Thu gọn',
        'unfold': 'Mở rộng',
    },
    'id-id': {
        'fold': 'Letakkan itu pergi',
        'unfold': 'peluncuran',
    }
}
