export const Uidmap = {
    "UID:P_TINPERNEXT-FE_184FD33005600017": ["calendar-today", "datePicker-today"],
    "UID:P_TINPERNEXT-FE_184FD33005600018": ["calendar-now", "datePicker-now", "timePicker-now"],
    "UID:P_TINPERNEXT-FE_184FD33005600019": ["calendar-backToToday", "datePicker-backToToday"],
    "UID:P_TINPERNEXT-FE_184FD3300560001A": ["calendar-ok", "modal-ok", "table-ok", "datePicker-ok", "timePicker-ok", "popconfirm-ok", "pagination-ok"],
    "UID:P_TINPERNEXT-FE_184FD3300560001B": ["calendar-timeSelect", "datePicker-timeSelect"],
    "UID:P_TINPERNEXT-FE_184FD3300560001C": ["calendar-dateSelect", "datePicker-dateSelect"],
    "UID:P_TINPERNEXT-FE_184FD3300560001D": ["calendar-weekSelect", "datePicker-weekSelect"],
    "UID:P_TINPERNEXT-FE_184FD3300560001E": ["calendar-clear", "datePicker-clear", "timePicker-clear"],
    "UID:P_TINPERNEXT-FE_184FD3300560001F": ["calendar-month", "datePicker-month"],
    "UID:P_TINPERNEXT-FE_184FD33005600020": ["calendar-year", "datePicker-year"],
    "UID:P_TINPERNEXT-FE_184FD33005600021": "calendar-previousMonth",
    "UID:P_TINPERNEXT-FE_184FD33005600022": "calendar-nextMonth",
    "UID:P_TINPERNEXT-FE_184FD33005600023": ["calendar-monthSelect", "datePicker-monthSelect"],
    "UID:P_TINPERNEXT-FE_184FD33005600024": ["calendar-yearSelect", "datePicker-yearSelect"],
    "UID:P_TINPERNEXT-FE_184FD33005600025": ["calendar-decadeSelect", "datePicker-decadeSelect"],
    "UID:P_TINPERNEXT-FE_184FD3300560002A": "calendar-previousYear",
    "UID:P_TINPERNEXT-FE_184FD3300560002B": "calendar-nextYear",
    "UID:P_TINPERNEXT-FE_184FD3300560002C": ["calendar-previousDecade", "datePicker-previousDecade"],
    "UID:P_TINPERNEXT-FE_184FD3300560002D": ["calendar-nextDecade", "datePicker-nextDecade"],
    "UID:P_TINPERNEXT-FE_184FD3300560002E": ["calendar-previousCentury", "datePicker-previousCentury"],
    "UID:P_TINPERNEXT-FE_184FD3300560002F": ["calendar-nextCentury", "datePicker-nextCentury"],
    "UID:P_TINPERNEXT-FE_184FD33005600030": "calendar-clickSelectColomn",
    "UID:P_TINPERNEXT-FE_184FD33005600031": "calendar-clickSelect",
    "UID:P_TINPERNEXT-FE_184FD33005600032": "calendar-date",
    "UID:P_TINPERNEXT-FE_184FD33005600033": "calendar-toLastDay",
    "UID:P_TINPERNEXT-FE_184FD33005600034": "calendar-toNextDay",
    "UID:P_TINPERNEXT-FE_184FD33005600035": ["calendar-toLastMonth", "datePicker-previousMonth"],
    "UID:P_TINPERNEXT-FE_184FD33005600036": ["calendar-toNextMonth", "datePicker-nextMonth"],
    "UID:P_TINPERNEXT-FE_184FD33005600037": "calendar-allDay",
    "UID:P_TINPERNEXT-FE_184FD33005600038": ["calendar-months", "datePicker-months"],
    "UID:P_TINPERNEXT-FE_184FD33005600039": ["calendar-monthsShort", "datePicker-monthsShort"],
    "UID:P_TINPERNEXT-FE_184FD3300560003A": ["calendar-weekdaysMin", "datePicker-weekdaysMin"],
    "UID:P_TINPERNEXT-FE_184FD3300560003B": ["calendar-weekdaysShort", "datePicker-weekdaysShort"],
    "UID:P_TINPERNEXT-FE_184FD3300560003C": ["calendar-firstDay", "datePicker-firstDay"],
    "UID:P_TINPERNEXT-FE_1875A9F605600005": "calendar-janFirst",
    "UID:P_TINPERNEXT-FE_1875AA2A05600005": "calendar-febFirst",
    "UID:P_TINPERNEXT-FE_1875AA5405600008": "calendar-marFirst",
    "UID:P_TINPERNEXT-FE_1875AA9205B00004": "calendar-aprFirst",
    "UID:P_TINPERNEXT-FE_1875AAE005600000": "calendar-mayFirst",
    "UID:P_TINPERNEXT-FE_1875AB1E05B00009": "calendar-junFirst",
    "UID:P_TINPERNEXT-FE_1875AC7805B00003": "calendar-julFirst",
    "UID:P_TINPERNEXT-FE_1875AB9E05600003": "calendar-augFirst",
    "UID:P_TINPERNEXT-FE_1875ABBA05B00009": "calendar-sepFirst",
    "UID:P_TINPERNEXT-FE_1875ABE405B00001": "calendar-octFirst",
    "UID:P_TINPERNEXT-FE_1875AC0E05600006": "calendar-novFirst",
    "UID:P_TINPERNEXT-FE_1875AC2E05B00004": "calendar-decFirst",
    "UID:P_TINPERNEXT-FE_1C06126605200006": "calendar-modalHeader",
    "UID:P_TINPERNEXT-FE_1C0612E204580006": "calendar-modalBody",
    "UID:P_TINPERNEXT-FE_1C06141E04580007": "calendar-ok",
    "UID:P_TINPERNEXT-FE_184FD3300560003D": ["clipboard-copy", "errorMessage-copy"],
    "UID:P_TINPERNEXT-FE_184FD3300560003E": "clipboard-cut",
    "UID:P_TINPERNEXT-FE_184FD3300560003F": "clipboard-copyReady",
    "UID:P_TINPERNEXT-FE_184FD33005600040": "clipboard-cutReady",
    "UID:P_TINPERNEXT-FE_184FD33005600041": "clipboard-copyToClipboard",
    "UID:P_TINPERNEXT-FE_184FD33005600042": "clipboard-close",
    "UID:P_TINPERNEXT-FE_184FD33005600043": "inputNumber-msgMax",
    "UID:P_TINPERNEXT-FE_184FD33005600044": "inputNumber-msgMin",
    "UID:P_TINPERNEXT-FE_1C05FED804580008": "inputNumber-hundred",
    "UID:P_TINPERNEXT-FE_1C06043805200003": "inputNumber-thousand",
    "UID:P_TINPERNEXT-FE_1C060A9405200004": "inputNumber-tenThousand",
    "UID:P_TINPERNEXT-FE_1C060B2A05200008": "inputNumber-hundredThousand",
    "UID:P_TINPERNEXT-FE_1C0613F605200001": "inputNumber-million",
    "UID:P_TINPERNEXT-FE_1C06149804580006": "inputNumber-tenMillion",
    "UID:P_TINPERNEXT-FE_1C0614E605200008": "inputNumber-hundredMillion",
    "UID:P_TINPERNEXT-FE_1C06151205200005": "inputNumber-billion",
    "UID:P_TINPERNEXT-FE_1C06153605200007": "inputNumber-tenBillion",
    "UID:P_TINPERNEXT-FE_1C06156605200002": "inputNumber-hundredBillion",
    "UID:P_TINPERNEXT-FE_1C06159004580004": "inputNumber-trillion",
    "UID:P_TINPERNEXT-FE_1C0615B805200001": "inputNumber-tenTrillion",
    "UID:P_TINPERNEXT-FE_1C0615E205200003": "inputNumber-hundredTrillion",
    "UID:P_TINPERNEXT-FE_1C06161404580007": "inputNumber-quadrillion",
    "UID:P_TINPERNEXT-FE_184FD33005600045": ["spin-loading", 'pagination-loading'],
    "UID:P_TINPERNEXT-FE_184FD33005600046": ["treeSelect-notFoundContent", "select-notFoundContent", "transfer-notFoundContent", "table-noData", "cascader-notFoundContent"],
    "UID:P_TINPERNEXT-FE_184FD33005600047": ["empty-noVisualizeDataImg", "empty-noDataImg"],
    "UID:P_TINPERNEXT-FE_184FD33005600048": "empty-noCollectImg",
    "UID:P_TINPERNEXT-FE_184FD33005600049": "empty-noSearchImg",
    "UID:P_TINPERNEXT-FE_184FD3300560004A": "empty-noNetworkImg",
    "UID:P_TINPERNEXT-FE_184FD3300560004B": "modal-gotit",
    "UID:P_TINPERNEXT-FE_184FD3300560004C": ["modal-cancel", "table-cancel", "popconfirm-cancel", "calendar-cancel"],
    "UID:P_TINPERNEXT-FE_184FD3300560004D": "modal-title",
    "UID:P_TINPERNEXT-FE_184FD3300560004E": ["steps-text", "calendar-more"],
    "UID:P_TINPERNEXT-FE_184FD3300560004F": "cascader-placeholder",
    "UID:P_TINPERNEXT-FE_184FD33005600050": "transfer-source",
    "UID:P_TINPERNEXT-FE_184FD33005600051": "transfer-target",
    "UID:P_TINPERNEXT-FE_184FD33005600052": "transfer-searchPlaceholder",
    "UID:P_TINPERNEXT-FE_184FD33005600053": "table-resetSettings",
    "UID:P_TINPERNEXT-FE_184FD33005600054": "table-include",
    "UID:P_TINPERNEXT-FE_184FD33005600055": "table-exclusive",
    "UID:P_TINPERNEXT-FE_184FD33005600056": ["table-equal", "table-beEqualTo"],
    "UID:P_TINPERNEXT-FE_184FD33005600057": ["table-unequal", "table-notEqualTo"],
    "UID:P_TINPERNEXT-FE_184FD33005600058": "table-begin",
    "UID:P_TINPERNEXT-FE_184FD33005600059": "table-end",
    "UID:P_TINPERNEXT-FE_184FD3300560005A": "table-greaterThan",
    "UID:P_TINPERNEXT-FE_184FD3300560005B": "table-greatThanEqualTo",
    "UID:P_TINPERNEXT-FE_184FD3300560005C": "table-lessThan",
    "UID:P_TINPERNEXT-FE_184FD3300560005D": "table-lessThanEqualTo",
    "UID:P_TINPERNEXT-FE_184FD3300560005E": "table-boolTrue",
    "UID:P_TINPERNEXT-FE_184FD3300560005F": "table-boolFalse",
    "UID:P_TINPERNEXT-FE_18883F1A05600002": "table-subtotal",
    "UID:P_TINPERNEXT-FE_184FD33005600061": "table-showRowNum",
    "UID:P_TINPERNEXT-FE_184FD33005600062": "table-all",
    "UID:P_TINPERNEXT-FE_184FD33005600063": "table-searchNoRow",
    "UID:P_TINPERNEXT-FE_184FD33005600064": "table-alreadyFirstRow",
    "UID:P_TINPERNEXT-FE_184FD33005600065": "table-alreadyLastRow",
    "UID:P_TINPERNEXT-FE_184FD33005600066": "table-expandAll",
    "UID:P_TINPERNEXT-FE_184FD33005600067": "table-collapseAll",
    "UID:P_TINPERNEXT-FE_184FD33005600068": "table-overlayUpText",
    "UID:P_TINPERNEXT-FE_184FD33005600069": "table-overlayDownText",
    "UID:P_TINPERNEXT-FE_184FD3300560006A": "table-cancelOverlay",
    "UID:P_TINPERNEXT-FE_184FD3300560006B": "table-selectAll",
    "UID:P_TINPERNEXT-FE_184FD3300560006C": "table-invertCurrentPage",
    "UID:P_TINPERNEXT-FE_184FD3300560006D": "table-clearAll",
    "UID:P_TINPERNEXT-FE_184FD3300560006E": "table-nullValue",
    'UID:P_TINPERNEXT-FE_1925685205900002': "table-selAll",
    "UID:P_TINPERNEXT-FE_184FD3300560006F": "datePicker-firstHalfYear",
    "UID:P_TINPERNEXT-FE_184FD33005600070": "datePicker-secondHalfYear",
    "UID:P_TINPERNEXT-FE_1853593C05380004": "datePicker-quarter",
    "UID:P_TINPERNEXT-FE_184FD33005600072": "datePicker-previousYear",
    "UID:P_TINPERNEXT-FE_184FD33005600073": "datePicker-nextYear",
    "UID:P_TINPERNEXT-FE_184FD33005600074": "pagination-total",
    "UID:P_TINPERNEXT-FE_184FD33005600075": "pagination-items",
    "UID:P_TINPERNEXT-FE_184FD33005600076": "pagination-show",
    "UID:P_TINPERNEXT-FE_184FD33005600077": "pagination-goto",
    "UID:P_TINPERNEXT-FE_184FD33005600078": "pagination-page",
    "UID:P_TINPERNEXT-FE_184FD33005600079": "pagination-prev",
    "UID:P_TINPERNEXT-FE_184FD3300560007A": "pagination-next",
    "UID:P_TINPERNEXT-FE_184FD3300560007B": "pagination-first",
    "UID:P_TINPERNEXT-FE_184FD3300560007C": "pagination-last",
    "UID:P_TINPERNEXT-FE_184FD3300560007D": "upload-remove",
    "UID:P_TINPERNEXT-FE_184FD3300560007E": "upload-down",
    "UID:P_TINPERNEXT-FE_184FD3300560007F": "upload-fileLoading",
    "UID:P_TINPERNEXT-FE_184FD33005600080": "upload-toview",
    "UID:P_TINPERNEXT-FE_184FD33005600081": "upload-preview",
    'UID:P_TINPERNEXT-FE_1850721405900004': "empty-noFound",
    'UID:P_TINPERNEXT-FE_184FD33005600060': "table-total",
    'UID:P_TINPERNEXT-FE_1AFACDC004080004': "image-prev",
    'UID:P_TINPERNEXT-FE_1AFACFCA04080001': "image-next",
    'UID:P_TINPERNEXT-FE_1AFAD00804080005': "image-zoomOut",
    'UID:P_TINPERNEXT-FE_1AFAD02A04080006': "image-zoomIn",
    'UID:P_TINPERNEXT-FE_1AFAD06604780002': "image-rotateLeft",
    'UID:P_TINPERNEXT-FE_1AFAD08A04780005': "image-rotateRight",
    'UID:P_TINPERNEXT-FE_1AFAD0C004080006': "image-flipHorizontal",
    'UID:P_TINPERNEXT-FE_1AFAD0DC04780002': "image-flipVertical",
    'UID:P_TINPERNEXT-FE_1AFAD0F604080004': "image-reset",
    'UID:P_TINPERNEXT-FE_1B13283A05F00001': "image-preview",
    'UID:P_TINPERNEXT-FE_1B13302205F00004': ["typography-unfold", "layout-unfold"],
    'UID:P_TINPERNEXT-FE_1B13304005F00005': ["typography-fold", "layout-fold"],
    'UID:P_TINPERNEXT-FE_1B27809604980000': "table-cancelLock",
    'UID:P_TINPERNEXT-FE_1B8C297E04A00000': "table-onlyFilterThis",
    'UID:P_TINPERNEXT-FE_1B8C294804F00007': "table-clearFilter",
    'UID:P_TINPERNEXT-FE_1CF0110204580007': 'errorMessage-statuscode',
    'UID:P_TINPERNEXT-FE_1CF011EC04580000': 'errorMessage-link',
    'UID:P_TINPERNEXT-FE_1CF0122004100001': 'errorMessage-errorDetails',
    'UID:P_TINPERNEXT-FE_1CF0170404580005': 'errorMessage-copySuccess',
    'UID:P_TINPERNEXT-FE_1CF01C3C04580005': 'errorMessage-report',
    'UID:P_TINPERNEXT-FE_1CF01C8804100007': 'errorMessage-reportSuccess',
    'UID:P_TINPERNEXT-FE_1CF01D5E04100006': 'errorMessage-reportFail',
    "UID:P_TINPERNEXT-FE_1C1A278C04800009": "calendar-moreAfter",
    "UID:P_TINPERNEXT-FE_1C5CBE8405000004": "table-copyText",
    "UID:P_TINPERNEXT-FE_1C1A27BA04C80000": "calendar-week",
    "UID:P_TINPERNEXT-FE_1C5CBF3605000003": "table-copyCols",
    "UID:P_TINPERNEXT-FE_1C1A275404C80006": "calendar-moreBefore"
}