export default {
    'lang': 'zh-cn',
    'resetSettings': '重置',
    'selAll': '全选',
    'include': '包含',
    'exclusive': '不包含',
    'equal': '等于',
    'unequal': '不等于',
    'begin': '以开始',
    'end': '以结尾',
    'greaterThan': '大于',
    'greatThanEqualTo': '大于等于',
    'lessThan': '小于',
    'lessThanEqualTo': '小于等于',
    'beEqualTo': '等于',
    'notEqualTo': '不等于',
    "noData": '暂无数据',
    "boolTrue": "是",
    "boolFalse": "否",
    "subtotal": "小计",
    'total': '合计',
    'ok': '确定',
    'cancel': '取消',
    'showRowNum': '序号',
    'all': '全部',
    'searchNoRow': '未搜索到匹配的行！',
    'alreadyFirstRow': '已经是匹配的第一行！',
    'alreadyLastRow': '已经是匹配的最后一行！',
    'expandAll': '批量展开',
    'collapseAll': '批量收起',
    'overlayUpText': '点击升序',
    'overlayDownText': '点击降序',
    'cancelOverlay': '点击取消排序',
    'selectAll': '全选所有',
    'invertCurrentPage': '反选当页',
    'clearAll': '清空所有',
    'nullValue': '空值',
    'cancelLock': '锁定区域超出屏幕范围, 已为您临时取消锁定',
    'clearFilter': '清除筛选',
    'onlyFilterThis': '仅筛选此项',
    'copyText': '复制文本',
    'copyRows': '复制整行',
    'copyCols': '复制整列',
    'zh-cn': {
        'resetSettings': '重置',
        'selAll': '全选',
        'include': '包含',
        'exclusive': '不包含',
        'equal': '等于',
        'unequal': '不等于',
        'begin': '以开始',
        'end': '以结尾',
        'greaterThan': '大于',
        'greatThanEqualTo': '大于等于',
        'lessThan': '小于',
        'lessThanEqualTo': '小于等于',
        'beEqualTo': '等于',
        'notEqualTo': '不等于',
        "noData": '暂无数据',
        "boolTrue": "是",
        "boolFalse": "否",
        "subtotal": "小计",
        'total': '合计',
        'ok': '确定',
        'cancel': '取消',
        'showRowNum': '序号',
        'all': '全部',
        'searchNoRow': '未搜索到匹配的行！',
        'alreadyFirstRow': '已经是匹配的第一行！',
        'alreadyLastRow': '已经是匹配的最后一行！',
        'expandAll': '批量展开',
        'collapseAll': '批量收起',
        'overlayUpText': '点击升序',
        'overlayDownText': '点击降序',
        'cancelOverlay': '点击取消排序',
        'selectAll': '全选所有',
        'invertCurrentPage': '反选当页',
        'clearAll': '清空所有',
        'nullValue': '空值',
        'cancelLock': '锁定区域超出屏幕范围, 已为您临时取消锁定',
        'clearFilter': '清除筛选',
        'onlyFilterThis': '仅筛选此项',
        'copyText': '复制文本',
        'copyRows': '复制整行',
        'copyCols': '复制整列',
    },
    'en-us': {
        'resetSettings': 'Reset',
        'selAll': 'Select All',
        'include': 'Include',
        'exclusive': 'Not include',
        'equal': 'Equal to',
        'unequal': 'Not equal to',
        'begin': 'Begin with',
        'end': 'End with',
        'greaterThan': 'Greater than',
        'greatThanEqualTo': 'Greater than or equal to',
        'lessThan': 'Less than',
        'lessThanEqualTo': 'Less than or equal to',
        'beEqualTo': 'Equal to',
        'notEqualTo': 'Not equal to',
        "noData": 'No data',
        "boolTrue": "true",
        "boolFalse": "false",
        "subtotal": "Subtotal",
        'total': 'Total',
        'ok': 'OK',
        'cancel': 'Cancel',
        'showRowNum': 'Serial number',
        'all': 'All',
        'searchNoRow': 'No matching rows found!',
        'alreadyFirstRow': 'It is already the first matching line!',
        'alreadyLastRow': 'It is already the last line of the match!',
        'expandAll': 'Batch expansion',
        'collapseAll': 'Bulk pack',
        'overlayUpText': 'Click ascending',
        'overlayDownText': 'Click descending',
        'cancelOverlay': 'Click Unsort',
        'selectAll': 'Select All',
        'invertCurrentPage': 'Invert The Current Page',
        'clearAll': 'Clear All',
        'nullValue': 'Null value',
        'cancelLock': 'The locked area is out of range of the screen and has been temporarily unlocked for you',
        'clearFilter': 'Clear the filter',
        'onlyFilterThis': 'Filter for this item only',
        'copyText': 'Copy the text',
        'copyRows': 'Copy full line',
        'copyCols': 'Copy full column',
    },
    'zh-tw': {
        'resetSettings': '重置',
        'selAll': '全選',
        'include': '包含',
        'exclusive': '不包含',
        'equal': '等於',
        'unequal': '不等於',
        'begin': '以開始',
        'end': '以結尾',
        'greaterThan': '大於',
        'greatThanEqualTo': '大於等於',
        'lessThan': '小於',
        'less_than_equal to': '小於等於',
        'beEqualTo': '等於',
        'notEqualTo': '不等於',
        "noData": '暫無數據',
        "boolTrue": "是",
        "boolFalse": "否",
        "subtotal": "小計",
        'total': '合計',
        'cancel': '取消',
        'ok': '確定',
        'showRowNum': '序號',
        'all': '全部',
        'searchNoRow': '未蒐索到匹配的行!',
        'alreadyFirstRow': '已經是匹配的第一行！',
        'alreadyLastRow': '已經是匹配的最後一行！',
        'expandAll': '批量展開',
        'collapseAll': '批量收起',
        'overlayUpText': '點擊升序',
        'overlayDownText': '點擊降序',
        'cancelOverlay': '點擊取消排序',
        'selectAll': '全選所有',
        'invertCurrentPage': '反選當頁',
        'clearAll': '清空所有',
        'nullValue': '空值',
        'cancelLock': '鎖定區域超出螢幕範圍， 已為您暫時取消鎖定',
        'clearFilter': '清除篩選',
        'onlyFilterThis': '僅篩選此項',
        'copyText': '複製文字',
        'copyRows': '複製整行',
        'copyCols': '複製整列',
    },
    'vi-vn': {
        'resetSettings': 'Đặt lại',
        'selAll': 'Tất cả các lựa chọn',
        'include': 'Có chứa',
        'exclusive': 'Không bao gồm',
        'equal': 'Có nghĩa là',
        'unequal': 'Không bằng',
        'begin': 'Để bắt đầu',
        'end': 'Để kết thúc',
        'greaterThan': 'Lớn hơn',
        'greatThanEqualTo': 'Lớn hơn hoặc bằng',
        'lessThan': 'Nhỏ hơn',
        'lessThanEqualTo': 'Ít hơn hoặc bằng',
        'beEqualTo': 'Có nghĩa là',
        'notEqualTo': 'Không bằng',
        "noData": 'Không có dữ liệu',
        "boolTrue": "là",
        "boolFalse": "Nếu không",
        "subtotal": "Kế hoạch nhỏ",
        'total': 'tất cả',
        'ok': 'Chắc chắn',
        'cancel': 'Hủy bỏ',
        'showRowNum': 'Số seri',
        'all': 'Tất cả',
        'searchNoRow': 'Không tìm thấy dòng phù hợp!',
        'alreadyFirstRow': 'Đã là dòng đầu tiên của trận đấu!',
        'alreadyLastRow': 'Đã là dòng cuối cùng của trận đấu!',
        'expandAll': 'Mở rộng hàng loạt',
        'collapseAll': 'Số lượng lớn',
        'overlayUpText': 'Nhấp chuột lên',
        'overlayDownText': 'Nhấp chuột xuống',
        'cancelOverlay': 'Nhấn vào để hủy bỏ việc sắp xếp',
        'selectAll': 'Chọn tất cả',
        'invertCurrentPage': 'Chọn ngược trang',
        'clearAll': 'Xóa hết',
        'nullValue': 'Giá trị null',
        'cancelLock': 'Khu vực bị khóa nằm ngoài phạm vi phủ sóng của màn hình và đã được mở khóa tạm thời cho bạn',
        'clearFilter': 'Xóa bộ lọc',
        'onlyFilterThis': 'Chỉ lọc cho mục này',
        'copyText': 'SAO chép văn bản',
        'copyRows': 'SAO chép toàn bộ dòng',
        'copyCols': 'SAO chép toàn bộ cột',
    },
    'id-id': {
        'resetSettings': 'Reset',
        'selAll': 'Pilih semua',
        'include': 'Mencakup',
        'exclusive': 'Tidak mencakup',
        'equal': 'Sama dengan',
        'unequal': 'Tidak sama dengan',
        'end': 'Dengan akhir',
        'greaterThan': 'Lebih besar dari',
        'greatThanEqualTo': 'Lebih besar atau sama dengan',
        'lessThan': 'Lebih kecil dari',
        'lessThanEqualTo': 'Lebih kecil atau sama dengan',
        'beEqualTo': 'Sama dengan',
        'notEqualTo': 'Tidak sama dengan',
        "noData": 'Tidak ada data',
        "boolTrue": "Ya",
        "boolFalse": "Tidak",
        "subtotal": "subtotal",
        'total': 'seluruh',
        'ok': 'Baik',
        'cancel': 'Batal',
        'showRowNum': 'Nomor seri',
        'all': 'Semua',
        'searchNoRow': 'Tidak ada baris yang cocok!',
        'alreadyFirstRow': 'Sudah baris pertama yang cocok!',
        'alreadyLastRow': 'Sudah baris terakhir yang cocok!',
        'expandAll': 'Perluas semua',
        'collapseAll': 'Lipat semua',
        'overlayUpText': 'Klik untuk urutan meningkat',
        'overlayDownText': 'Klik untuk urutan menurun',
        'cancelOverlay': 'Klik untuk membatalkan pengurutan',
        'selectAll': 'Pilih semua',
        'invertCurrentPage': 'Inversi seleksi halaman',
        'clearAll': 'Bersihkan semua',
        'cancelLock': 'Area terkunci berada di luar jangkauan layar dan telah dibuka untuk Anda sementara',
        'clearFilter': 'Hapus filter',
        'onlyFilterThis': 'Filter hanya untuk item ini',
        'copyText': 'Salin teks',
        'copyRows': 'Salin seluruh baris',
        'copyCols': 'Salin kolom lengkap',
    }
}
